<template>
  <banner class="mb-4" :backgroundImage="$maxxpm.config.faq.banner.image">
    <div class="pt-5 pb-5">
      <h1 class="text-white text-center">Frequently Asked Questions</h1>
    </div>
  </banner>
  <div class="questions-container">
    <h2 class="display-6 text-primary text-center">
      Click on a Question Below
    </h2>
    <Accordion>
      <AccordionTab
        v-for="(faq, index) in faqs"
        :key="'faq-' + index"
        :header="faq.question"
      >
        <p v-if="faq.links" class="text-start" v-html="faq.answer"></p>
        <p v-else class="text-start">{{ faq.answer }}</p>
        <div v-if="faq.list">
          <ul v-if="typeof faq.list[0] === 'object'">
            <li v-for="li in faq.list" :key="li.answer" class="text-start">
              {{ li.answer }}
              <ul class="mt-1">
                <li v-for="sli in li.sublist" :key="sli" class="text-start">
                  <p v-if="faq.links" v-html="sli" class="m-0"></p>
                  <p v-else class="m-0">{{ sli }}</p>
                </li>
              </ul>
            </li>
          </ul>
          <ul v-else>
            <li v-for="li in faq.list" :key="li" class="text-start">
              <p v-if="faq.links" v-html="li" class="m-0"></p>
              <p v-else class="m-0">{{ li }}</p>
            </li>
          </ul>
        </div>
        <p class="text-start">{{ faq.footnote }}</p>
      </AccordionTab>
    </Accordion>

    <h2 class="mt-3" role="heading">
      Other Important Information, Per Your Lease:
    </h2>
    <ul>
      <li v-for="li in otherInfo" :key="li" class="text-start">
        <p v-html="li"></p>
      </li>
    </ul>
    <p class="mt-5 text-center">
      These policies are subject to change at any time.
    </p>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { Banner } from "@maxxpm/vue-components";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

const $maxxpm = inject("$maxxpm");

const faqs = ref([
  {
    question: "What does it cost to add an animal to my lease?",
    answer:
      "There is a $75 lease change fee to add an animal to your lease. There is a 2-animal maximum per unit.",
  },
  {
    question: "How do I remove an animal from my lease?",
    answer:
      "There is a $75 lease change fee and a $50 inspection fee to confirm animal has been removed from the premises. If you can provide documentation from the veterinarian that the animal has been put down, the $50 inspection fee can be waived.",
  },
  {
    question: "Why does my dog have to be DNA tested?",
    answer:
      "The HOA requires DNA testing. The fine for leaving feces in the community is $500 plus a $50 service of notice fee.",
  },
  {
    question: "How do I terminate my lease early?",
    answer:
      "Section 4.1 of your lease states that if you would like to vacate the premises early (get out of your lease), all rents for the remainder of the lease will be accelerated and due immediately. As a courtesy to you, MAXXPM offers a 3-month rent/fee buyout with a required 10 days‘ notice.",
  },
  {
    question:
      "How much does it cost for me to add or remove a tenant to the lease?",
    answer:
      "If a single roommate or member of the lease would like to be added/removed, there is a $150 lease change fee. Please note that we do not allow full tenant transfers; at least one individual from the original lease must remain on the lease for the entire term, including renewals.",
  },
  {
    question: "Do we allow full tenant transfers?",
    answer:
      "No. At least one individual from the original lease must remain on the lease for the entire term, including renewals.",
  },
  {
    question: "How do I get faster internet?",
    answer:
      "If your unit comes with internet via a tech package, you can upgrade the package by contacting the provider directly. There is an additional cost to the tenant upon upgrading.",
  },
  {
    question: "How do I find oute the specific parking rules fro my community?",
    answer:
      "Your community parking rules are included in the Move-in packet provided by email at the start of tenancy. Most communities are designed for a maximum of 2 vehicles per unit.",
  },
  {
    question: "What do I do if my vehicle has been towed?",
    answer:
      "If a car is towed, it is likely that there was a violation of community rules. Reach out to the towing company listed on the sign at the community entrance for next steps. We cannot dispute a tow or get your tow fees waived.",
  },
  {
    question: "How do I pay my first month's rent when I move in?",
    answer:
      "You can pay online at least 6 business days prior to move in.  Otherwise, you must pay the day you move in via certified funds made out to Maxx Property Management (cashier’s check or money order).",
  },
  {
    question: "Where do I pick up my keys on move-in day?",
    answer:
      "You can pick up keys at the office inside the clubhouse or at another otherwise designated location. Our office hours are 9:00-5:00 Monday-Friday.",
  },
  {
    question: "How do I pay my rent online?",
    answer:
      "Visit maxxpm.com and click on Tenant Portal to pay your rent online. Please note that there is a service charge to use a credit/debit card of: $10 or 2.99%, whichever is greater.  If you choose echeck (bank routing & account numbers) there is no service charge.",
  },
  {
    question: "Am I responsible for utilities?",
    answer:
      "You are responsible for the Gas and Electric utilities, which must be put in your name. Please see below or check your welcome packet for more information.",
    list: [
      {
        answer: "The Brickyard",
        sublist: [
          "Intermountain Gas: (800) 548-3679",
          "Idaho Power: (800) 488-6151",
        ],
      },
    ],
  },
  {
    question:
      "I need exterior maintenance taken care of for my property, who do I contact?",
    answer:
      "Regular site maintenance is scheduled throughout the year. If there is something more that needs to be taken care of between those times, please submit an exterior work order through your tenant portal.",
  },
  {
    question: "What repairs am I responsible for as a tenant?",
    answer:
      "Tenants are responsible for items such as lightbulbs, changing batteries, clogged drains, and any tenant-related damage.",
  },
  {
    question: "Do we provide paint codes?",
    answer:
      "No, we do not supply paint codes. If you would like to try to match your walls,  please bring at least a 1×1 inch paint chip to Sherwin-Williams. If that paint does not match, please note that you will still be charged at move-out to fix it.",
  },
  {
    question: "Do we provide vendor information?",
    answer:
      "No, we use a third-party vendor for turnover and repair services and are unable to supply their information.",
  },
  {
    question: "Will I receive my entire deposit back upon leaving?",
    answer:
      "You will receive your deposit back minus carpet cleaning fees, tenant related damage, & cleaning costs. Keep in mind, we do have high expectations in order to get the property back to a “like-new” rentable condition.",
  },
  {
    question:
      "If I get my carpets cleaned myself, can the carpet cleaning fee be waived?",
    answer: "",
    list: [
      "Per your lease, you are not permitted to get your carpets cleaned at move out. We require a professional vendor to do this so that we can verify clean carpets for the next tenant. The carpet cleaning charges are taken from your deposit.",
      "During your tenancy, you can have the carpets cleaned. If you choose to do so, you would be responsible for the bill.",
      "Please Note: Pet urine found on carpet will require carpet replacement. You will be responsible for carpet replacement costs, which will include carpet, padding, floorboard damage, floorboard replacement, etc.",
    ],
  },
  {
    question:
      "I saw something inappropriate in the community. Who do I report it to?",
    answer:
      "As a member of the community, you are an important set of eyes and ears for the mutual good and safety of all tenants. Please send the information to your property manager by text message or email. Reports will be kept confidential. In case of any emergency, please call 911.",
  },
  {
    question: "What is the smoking policy?",
    answer: "",
    list: [
      "Smoking and Vaping is absolutely forbidden in or around the premises, including your home or garage. “Smoking” means inhaling, exhaling, breathing, or carrying any lighted product, including but not limited to, cigarette, cigar, pipe, vapor smoking product/e-cigarette, other tobacco product, similar lighted product, marijuana and other illegal substances, in any manner or in any form.",
      "This includes all land within the community.",
    ],
  },
  {
    question:
      "What is the difference between renters insurance and our required tenant liability insurance?",
    answer: "",
    list: [
      "Renters insurance covers personal belongings. You will need to purchase this on your own; we do have a company that can provide it if you’re interested.",
      "Tenant Liability Insurance is required to live in our rentals. This insurance covers the structure itself with $100,000 of coverage and does not cover personal belongings.",
      "For more information, <a href='https://dn8z55bp0g7x7.cloudfront.net/Rental-Insurance.pdf' target='_blank'>click here.</a>",
    ],
    links: true,
  },
  {
    question:
      "Can I choose my own tenant liability insurance instead of the $9.50/month charge from MAXX Property Management?",
    answer: "Yes, you can. Your policy must include the following information:",
    list: [
      "The rental property address.",
      "MAXX PM 11845 S 700 E Suite 200, Draper, UT 84020 is listed as an additional interest",
      "At least $100,000 in liability",
      "If insurance is provided after the 1st of the month, we cannot remove our insurance fee for that month.",
      "If you are married, then only one of your names needs to be on the policy.",
      "If it is a roommate situation, then every roommate must get their own policy, or all be on the same policy before we remove our insurance.",
      "For more information, <a href='https://dn8z55bp0g7x7.cloudfront.net/Rental-Insurance.pdf' target='_blank'>click here.</a>",
    ],
    links: true,
  },
  {
    question: "Does MAXX PM report to credit bureaus?",
    answer:
      "Yes, we report to all major credit bureaus. Please note: If you have more than 3 adults on the lease, credit bureaus will not allow reporting.",
  },
  {
    question: "What are the rental qualifications?",
    answer:
      "You can find our Rental Qualifications <a href='/do-i-qualify'>HERE</a>.",
    links: true,
  },
  {
    question: "How do I view a self-showing property?",
    answer: "",
    list: [
      "Visit our Self Showing map <a href='https://www.maxxpm.com/self-showing-units/'>HERE</a>",
      "Use the drop-down menu above the map to choose which self-showing address you would like to visit.",
      "Once you have chosen, click on the “Self-Tour Now” button that shows up on the map.",
      "Follow instructions given on with Rently Self Tours that will be sent to you.",
      "You will be charged $1 per transaction but that same $1 will be refunded and dropped off your credit card after the viewing and our vetting of the property. This is for safety, to keep the property secure, and allow you to enter unattended by our staff. This deposit is required to view the property.",
    ],
    links: true,
  },
  {
    question: "How do I submit a maintenance request?",
    answer: "",
    list: [
      "Log In to your Portal.",
      "Click Maintenance on the left side.",
      "Click Request Maintenance.",
      "Add information and pictures for your request.",
    ],
    footnote:
      "If this is an emergency, please contact our emergency maintenance line at (855) 240-8412.",
  },
  {
    question:
      "How long will it take for my maintenance request to be completed?",
    answer:
      "Maintenance Requests are typically scheduled or contacted within 72 business hours. If it is an emergency, please contact our emergency maintenance line at (855) 240-8412.",
  },
  {
    question: "How much does it cost to apply?",
    answer:
      "Each person 18 years and older will need to fill out a complete application and submit a non-refundable application fee of $50.",
  },
  {
    question: "Can my application fees be refunded if I do not get approved?",
    answer:
      "No. The application fees are non-refundable and cannot be returned after an application has been run.",
  },
]);
const otherInfo = ref([
  "Per your lease, the rent is late after the 3rd of the month. Late fees will NOT be waived for ANY reason.",
  "If your payment bounces TWICE, then we do cut off access to pay online and you will be required to pay via certified funds for the remainder of your tenancy.",
  "When you pay rent via your tenant portal it posts to your ledger immediately then takes up to 5 business days to pull from your bank account.",
  "Please see your welcome email for HOA rules, internet info, and other important info.",
  "It is your responsibly to report any maintenance issues to MAXX PM via your tenant portal. You will be financially liable for any unreported issues.",
  "If a vendor calls for a service appointment and you ignore, refuse, or no-show, then you will be liable for the minimum $105 trip fee plus any repair charges.",
  "For any electrical issues please reset all GFCIs and breakers before you submit a maintenance request.  Please see our website for information videos. If the vendor finds this is the issue, then you will be billed.",
  "You are responsible for all light bulbs, air filters, and batteries in the unit. Please replace those before reporting an issue with the affected item. If the vendor finds this is the issue, then you will be billed.",
  "Before reporting a furnace or A/C issue be sure the filter is clean, nothing is blocking the return air vents, and the breaker is on. Please see our website for information videos. If the vendor finds this is the issue, then you will be billed.",
  "Before reporting no hot water, please light your water heater. If the water heater is electric you need to reset the breaker. If the water heater is gas you need to follow the directions on the water heater. Please see our website for information videos. If the vendor finds this is the issue, then you will be billed.",
  "Before reporting a garbage disposal issue, please use the tool underneath the disposal to break free any blockage and or press the red/black reset button at the bottom of the disposal. You may also see our website for information videos. If you’ve clogged/broke the disposal then you will be charged for the repair.",
  "If you have noise issues, please notify the police. If you have aggressive or loose animal issues, please notify animal control. Please notify MAXX PM as well so we can take proper action where needed.",
  "If you are locked out of your unit, please call a locksmith. If you want to purchase another key, you can request that through your property managers. We can make a copy and charge you for a new key only by your visiting our office during office hours.",
  "For after business hours emergencies <strong>(ONLY: no heat in winter or a flood) please call our after-hours service at 855-240-8412</strong>.",
]);
</script>

<style scoped>
.questions-container {
  width: 95%;
  max-width: 1200px;
  margin: auto;
}
</style>
